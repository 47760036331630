import { bindable, bindingMode, inject } from 'aurelia-framework';
import { I18N }                          from 'aurelia-i18n';
import { BaseListViewModel }             from 'base-list-view-model';
import { ConcessionsFilterFormSchema }   from 'modules/management/concessions/concessions-tree/concessions/listing/filter-form-schema';
import { ConcessionsTreeRepository }     from 'modules/management/concessions/concessions-tree/services/repository';
import { AppContainer }                  from 'resources/services/app-container';
import { Downloader }                    from 'resources/services/downloader';

@inject(AppContainer, ConcessionsTreeRepository, ConcessionsFilterFormSchema, I18N, Downloader)
export class ListConcessions extends BaseListViewModel {

    headerTitle    = 'listing.management.specifications.concessions';
    newRecordRoute = 'management.concessions.concessions.create';
    repository;
    datatable;

    ignoredFilters = ['parent_id', 'parent_key'];

    @bindable({ defaultBindingMode: bindingMode.twoWay }) listingId;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) filterFormIsDirty;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) filterModel;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) filterSchema;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) displayTree;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param filterFormSchema
     * @param i18N
     * @param downloader
     */
    constructor(appContainer, repository, filterFormSchema, i18N, downloader) {
        super(appContainer);

        this.repository       = repository;
        this.filterFormSchema = filterFormSchema;
        this.i18N             = i18N;
        this.downloader       = downloader;
    }

    /**
     * Handles activate event
     */
    bind() {
        this.filterSchema = this.filterFormSchema.schema(this);
        this.filterModel  = this.filterFormSchema.model(this);
        this.listingId    = 'management-concessions-concessions-listing';

        this.defineDatatable();
    }

    /**
     * Toggles the tree visibility
     */
    toggleTree() {
        this.displayTree = !this.displayTree;
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:      this.repository,
            show:            {
                action:  (row) => this.appContainer.router.navigateToRoute('management.concessions.concessions.view', { id: row.id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['management.concessions.concessions.manage', 'management.concessions.concessions.view']),
            },
            edit:            {
                action:  (row) => this.appContainer.router.navigateToRoute('management.concessions.concessions.edit', { id: row.id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['management.concessions.concessions.manage', 'management.concessions.concessions.edit']),
            },
            destroy:         {
                action:  (row) => this.repository.destroy(row.id),
                visible: (row) => this.appContainer.authenticatedUser.can(['management.concessions.concessions.manage', 'management.concessions.concessions.delete']),
            },
            options:         [
                {
                    label:   'form.button.export-to-excel',
                    icon:    'icon-file-excel',
                    action:  () => this.exportListing(this.i18N.tr('form.field.concessions'), !this.datatable.instance.selectedRows.length ? null : this.datatable.instance.selectedRows),
                    visible: (row) => this.appContainer.authenticatedUser.can(['management.concessions.concessions.manage', 'management.concessions.concessions.view']),
                },
            ],
            selectable:      true,
            destroySelected: true,
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            columns:         [
                {
                    data:  'code',
                    name:  'concessions.code',
                    title: 'form.field.code',
                },
                {
                    data:  'acronym',
                    name:  'concessions.acronym',
                    title: 'form.field.acronym',
                },
                {
                    data:  'description',
                    name:  'concession_translations.description',
                    title: 'form.field.description',
                },
                {
                    data:  'created_by',
                    name:  'users.name',
                    title: 'form.field.created-by',
                },
                {
                    data:            'created_at',
                    name:            'concessions.created_at',
                    title:           'form.field.created-at',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
            ],
        };
    }
}

import { bindable, inject, observable } from 'aurelia-framework';
import { BaseListViewModel }            from 'base-list-view-model';
import { ConcessionsFilterFormSchema }  from 'modules/management/concessions/concessions-tree/concessions/listing/filter-form-schema';
import { AppContainer }                 from 'resources/services/app-container';
import { ConcessionsTreeRepository }    from './services/repository';

@inject(AppContainer, ConcessionsTreeRepository, ConcessionsFilterFormSchema)
export class ListConcessionsTree extends BaseListViewModel {

    @bindable listingId;
    @bindable headerTitle    = 'listing.management.concessions.concessions';
    @bindable newRecordRoute = 'management.specifications.concessions.create';
    @bindable repository;
    @bindable datatable;

    ignoredFilters = ['parent_id', 'parent_key'];

    filterSchema;
    filterModel = {
        parent_id:  null,
        parent_key: null,
    };

    activeTreeNodeSuggestion = 'concessions_parent_id_suggestion';

    @bindable treeOptions = {
        id:             'concessions-concessions-trees-listing',
        click:          (event, data) => this.treeNodeClicked(event, data),
        activate:       (event, data) => this.treeNodeActivated(event, data),
        extensions:     [],
        shouldPersist:  false,
        filterable:     true,
        controlButtons: true,
        // TODO: Uncomment this line when the filters on the backend are ready, also persisting of the filter model is needed
        //filterSchema:   {
        //    viewModel: ConcessionsTreeFilterModal,
        //},

        nodeBaseInteractions: ['active', 'focus'],
    };

    @observable displayTree = true;
    displayList             = true;

    selectedType   = null;
    isConstruction = false;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param filterFormSchema
     */
    constructor(appContainer, repository, filterFormSchema) {
        super(appContainer);

        this.repository       = repository;
        this.filterFormSchema = filterFormSchema;

        this.treeOptions.repository = {
            source: this.repository,
            method: 'tree',
        };
    }

    displayTreeChanged(newValue, oldValue) {
        if (newValue !== oldValue) {
            this.onToggleTree();
        }
    }

    /**
     * Toggles the tree visibility
     */
    onToggleTree() {
        const savedParentKey           = this.appContainer.sessionStorage.get(this.activeTreeNodeSuggestion);
        this.treeOptions.initial_nodes = savedParentKey ? savedParentKey : null;

        if (!this.displayTree && this.filterModel.parent_id === null) {
            this.appContainer.sessionStorage.remove(this.activeTreeNodeSuggestion);

            this.appContainer.eventAggregator.publish('datatable-must-be-reloaded', { listingId: this.listingId, criteria: this.filterModel });
        }
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.concessions.concessions.manage',
            'management.concessions.concessions.view',
        ]);
    }

    /**
     * Toggles the tree visibility
     */
    toggleList() {
        this.displayList = !this.displayList;
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        if (!this.displayTree) {
            this.filterModel.parent_id  = null;
            this.filterModel.parent_key = null;
            this.appContainer.sessionStorage.remove(this.activeTreeNodeSuggestion);
        } else {
            const savedParentKey = this.appContainer.sessionStorage.get(this.activeTreeNodeSuggestion);
            if (savedParentKey) {
                this.filterModel.parent_key    = savedParentKey;
                this.treeOptions.initial_nodes = savedParentKey;

                const [type, id]           = savedParentKey.split('-');
                this.selectedType          = type;
                this.filterModel.parent_id = parseInt(id);
            }
        }
    }

    /**
     * Handles tree node click event
     *
     * @param event
     * @param data
     */
    treeNodeClicked(event, data) {
        let activeNode = data.tree.getActiveNode();

        if (activeNode) {
            activeNode.setFocus(false);
            activeNode.setActive(false);
        }

        if (activeNode === data.node) {
            this.filterModel.parent_id  = null;
            this.filterModel.parent_key = null;
            this.selectedType           = null;

            this.appContainer.sessionStorage.remove(this.activeTreeNodeSuggestion);
            this.appContainer.eventAggregator.publish('datatable-must-be-reloaded', { listingId: this.listingId, criteria: this.filterModel });

            return false;
        }
    }

    /**
     * Handles tree node activate event
     *
     * @param event
     * @param data
     */
    treeNodeActivated(event, data) {
        this.selectedType           = data.node.type;
        this.filterModel.parent_id  = parseInt(data.node.data.modelId);
        this.filterModel.parent_key = data.node.key;

        this.isConstruction = !!(this.selectedType && this.selectedType.includes('construction-'));

        this.treeOptions.initial_nodes = this.filterModel.parent_key;

        if (this.filterModel.parent_key) {
            this.appContainer.sessionStorage.set(this.activeTreeNodeSuggestion, this.filterModel.parent_key);
        } else {
            this.appContainer.sessionStorage.remove(this.activeTreeNodeSuggestion);
        }

        this.appContainer.eventAggregator.publish('datatable-must-be-reloaded', { listingId: this.listingId, criteria: this.filterModel });
    }
}
